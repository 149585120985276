import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { gradient } from '../../lib/gradient/gradient.js'
import ShapeAnimation from './ShapeAnimation'
import useIsInViewport from 'use-is-in-viewport'
import { down, only, up } from 'styled-breakpoints'

interface GradientColorsType {
	color1: string
	color2: string
	color3: string
	color4: string
}

interface IGradientCanvasProps {
	hasShape?: boolean
	gradientColors?: GradientColorsType
}

const defaultColor = {
	color1: '#fe7148',
	color2: '#53cbff',
	color3: '#b115ff',
	color4: '#fa2528',
}

const GradientCanvas = ({
	hasShape,
	gradientColors = defaultColor,
}: IGradientCanvasProps) => {
	const canvasRef = useRef(null)
	const [isInViewport, targetRef] = useIsInViewport()

	useEffect(() => {
		if (gradient && canvasRef) {
			gradient.initGradient(canvasRef)
		}
	}, [])

	//Gradient visible 상태에 따라 애니메이션 play & pause
	// useEffect(() => {
	// 	isInViewport ? gradient.initGradient(canvasRef) : gradient.pause()
	// }, [isInViewport])

	return (
		<Container ref={targetRef} gradientColors={gradientColors}>
			{hasShape && isInViewport ? <ShapeAnimation isColored={false} /> : ''}
			<canvas ref={canvasRef}></canvas>
		</Container>
	)
}

const Container = styled.div<{ gradientColors: GradientColorsType }>`
	transform: translateZ(0);
	width: 100%;
	height: 100%;
	padding: 0;
	overflow: hidden;
	position: relative;

	canvas {
		transform: rotateZ(360deg);

		-webkit-transform: rotateZ(360deg);
		height: 100%;
		width: 100%;
		--gradient-color-1: ${(props) => props.gradientColors.color1};
		--gradient-color-2: ${(props) => props.gradientColors.color2};
		--gradient-color-3: ${(props) => props.gradientColors.color3};
		--gradient-color-4: ${(props) => props.gradientColors.color4};
		backface-visibility: hidden;
		perspective: 1000;
		-webkit-backface-visibility: hidden;
		-webkit-perspective: 1000;
	}

	${up('lg')} {
		.polygon {
			margin-right: -195px;
		}
	}
`

export default GradientCanvas
