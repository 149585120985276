//Sets initial properties
export function e(object, propertyName, val) {
	return (
		propertyName in object
			? Object.defineProperty(object, propertyName, {
					value: val,
					enumerable: !0,
					configurable: !0,
					writable: !0,
			  })
			: (object[propertyName] = val),
		object
	)
}
